exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-languages-jsx": () => import("./../../../src/pages/languages.jsx" /* webpackChunkName: "component---src-pages-languages-jsx" */),
  "component---src-templates-barrier-to-entry-jsx": () => import("./../../../src/templates/barrier_to_entry.jsx" /* webpackChunkName: "component---src-templates-barrier-to-entry-jsx" */),
  "component---src-templates-competition-jsx": () => import("./../../../src/templates/competition.jsx" /* webpackChunkName: "component---src-templates-competition-jsx" */),
  "component---src-templates-demand-jsx": () => import("./../../../src/templates/demand.jsx" /* webpackChunkName: "component---src-templates-demand-jsx" */),
  "component---src-templates-ecosystem-jsx": () => import("./../../../src/templates/ecosystem.jsx" /* webpackChunkName: "component---src-templates-ecosystem-jsx" */),
  "component---src-templates-getting-help-jsx": () => import("./../../../src/templates/gettingHelp.jsx" /* webpackChunkName: "component---src-templates-getting-help-jsx" */),
  "component---src-templates-language-jsx": () => import("./../../../src/templates/language.jsx" /* webpackChunkName: "component---src-templates-language-jsx" */),
  "component---src-templates-syntax-jsx": () => import("./../../../src/templates/syntax.jsx" /* webpackChunkName: "component---src-templates-syntax-jsx" */),
  "component---src-templates-used-for-page-jsx": () => import("./../../../src/templates/used_for_page.jsx" /* webpackChunkName: "component---src-templates-used-for-page-jsx" */)
}

